.slides-ios,
.slides-md {
  --bullet-background: #A0A0A0;
  overflow: visible;
}

.swiper-wrapper {
  padding-bottom: 50px;
}

.swiper-slide {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.05);
  max-width: 220px;
}

@media(min-width: 1200px) {
  .swiper-slide {
    max-width: 400px;
  }
}

.slide-inner {
  min-height: 211px;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.slide-body {
  padding: 20px;
}

.slide-img {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.swiper-pagination-bullet {
  cursor: pointer;
}
