.app-logo {
  width: 120px;
  height: 120px;
  display: block;
  margin: var(--ion-margin-lg) auto;
}

.entry-content {
  line-height: 1.8;
}

.entry-content p {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: var(--ion-color-primary);
  text-decoration: none;
}

ion-loading {
  --spinner-color: var(--ion-color-primary) !important;
}

ion-footer ion-toolbar {
  padding: var(--ion-padding);
  --border-color: transparent;
}

.loading-content {
  font-weight: 600 !important;
}

.action-sheet-title {
  font-weight: 600 !important;
}

ion-action-sheet {
  --color: var(--ion-color-dark) !important;
}

ion-toast {
  font-weight: 600;
}

.footer-content {
  max-width: 484px;
  margin: 0 auto;
}

ion-footer ion-toolbar {
  padding: var(--ion-padding);
}
