ion-refresher {
  --color: var(--ion-color-primary-contrast);
}

ion-refresher-content {
  background: var(--ion-color-secondary);
  background-color: var(--ion-color-secondary);
  --color: var(--ion-color-primary-contrast);
}

.refresher-pulling .refresher-pulling-icon {
  color: var(--ion-color-primary-contrast);
}
