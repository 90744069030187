.d-flex {
  display: flex;
}

.d-block {
  display: block !important;
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-size-xs {
  font-size: 12px !important;
}

.font-size-sm {
  font-size: 14px !important;
}

.text-muted {
  color: var(--ion-color-medium);
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.has-fab-button {
  --padding-bottom: 56px;
}

.cursor-pointer {
  cursor: pointer;
}
