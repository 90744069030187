.card-group:not(:first-child) {
  margin-top: var(--ion-margin-lg);
}

.card-skeleton {
  height: 90px;
}

.card-skeleton-title {
  height: 10px;
}

.card-group-title {
  margin-bottom: 15px;
}

.card {
  --background: #fff;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.05);
  margin: 0 0 5px;
  min-height: 92px;
}

.card-column {
  max-width: 80%;
}

.card-title {
  color: #000;
  font-size: 16px !important;
}

.card-meta {
  font-size: 14px;
  margin-top: 8px;
}

.card-meta ion-text:first-child:after {
  content: '•';
  padding: 0 5px;
  font-family: sans-serif;
  color: var(--ion-color-medium);
}

.card-skeleton-icon {
  border-radius: 100%;
  width: 32px;
  display: block;
  height: 32px;
}
