.animate-down,
.animate-up {
  animation-fill-mode: forwards;
}

.animate-down {
  animation: animateDown 0.3s;
}

.animate-up {
  animation: animateUp 0.3s;
}

@keyframes animateDown {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animateUp {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
