@import 'brownforbkr.css';
@import 'animation.css';
@import 'app.css';
@import 'page.css';
@import 'helpers.css';
@import 'form.css';
@import 'heading.css';
@import 'button.css';
@import 'card.css';
@import 'object.css';
@import 'iframe.css';
@import 'refresher.css';
@import 'status-button.css';

:root {
  line-height: 1.5;
  font-weight: 600;
}
