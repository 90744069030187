.object-meta-group {
  margin-bottom: var(--ion-margin);
}

.object-meta-group:last-child,
.object-meta:last-child {
  margin-bottom: 0;
}

.object-meta {
  padding: 12px var(--ion-padding);
  margin-bottom: 5px;
  border: 1px solid rgba(255, 255, 255, 0.32);
  border-radius: 6px;
}
