.information-item {
  --padding-start: 0;
  --padding-end: 0;
  padding-top: 10px;
}

.information-item ion-label {
  white-space: normal !important;
  margin: 0;
}

.information-item ion-thumbnail {
  margin-top: 0;
  margin-bottom: 0;
}

.information-item h4 {
  font-size: 16px;
}
