h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  font-weight: 600 !important;
  margin: 0 0 10px;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 28px;
}

h2, .h2 {
  font-size: 18px;
}

h3, .h3,
h4, .h4 {
  font-size: 16px;
}
