
@font-face {
  font-family: 'BrownForBKR';
  src: url('../../assets/fonts/subset-BrownForBKR-Light.eot');
  src: url('../../assets/fonts/subset-BrownForBKR-Light.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/subset-BrownForBKR-Light.woff2') format('woff2'),
      url('../../assets/fonts/subset-BrownForBKR-Light.woff') format('woff'),
      url('../../assets/fonts/subset-BrownForBKR-Light.ttf') format('truetype'),
      url('../../assets/fonts/subset-BrownForBKR-Light.svg#BrownForBKR-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'BrownForBKR';
  src: url('../../assets/fonts/subset-BrownForBKR-Bold.eot');
  src: url('../../assets/fonts/subset-BrownForBKR-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/subset-BrownForBKR-Bold.woff2') format('woff2'),
      url('../../assets/fonts/subset-BrownForBKR-Bold.woff') format('woff'),
      url('../../assets/fonts/subset-BrownForBKR-Bold.ttf') format('truetype'),
      url('../../assets/fonts/subset-BrownForBKR-Bold.svg#BrownForBKR-Bold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'BrownForBKR';
  src: url('../../assets/fonts/subset-BrownForBKR-Regular.eot');
  src: url('../../assets/fonts/subset-BrownForBKR-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/subset-BrownForBKR-Regular.woff2') format('woff2'),
      url('../../assets/fonts/subset-BrownForBKR-Regular.woff') format('woff'),
      url('../../assets/fonts/subset-BrownForBKR-Regular.ttf') format('truetype'),
      url('../../assets/fonts/subset-BrownForBKR-Regular.svg#BrownForBKR-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

