ion-toolbar ion-menu-button {
  color: var(--ion-color-primary-contrast);
}

@media (max-width: 576px) {
  ion-menu {
    --min-width: 90%;
  }
}

.menu-item-link {
  display: flex;
  flex: 1;
  align-items: center;
  color: var(--ion-color-dark);
  text-decoration: none;
}

.menu-item-link.active ion-icon,
.menu-item-link.active ion-label {
  color: var(--ion-color-primary);
}

ion-menu ion-list {
  margin-bottom: var(--ion-spacer) !important;
  background-color: var(--ion-color-white) !important;
}

ion-menu ion-button {
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  font-size: 14px;
}

ion-menu ion-icon {
  margin-right: 12px;
}

.menu-user-info {
  line-height: 1.5;
  margin: 16px 16px 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.menu-user-info ion-icon {
  padding: 10px;
  background: #eee;
  border-radius: 100%;
}

.menu-inner {
  height: 100%;
  flex-direction: column;
}

.contact-info {
  color: var(--ion-color-medium);
  font-size: 12px;
  line-height: 1.7;
  margin: 30px 20px;
  padding: var(--ion-padding) 0;
  border-top: 1px solid #eee;
}

.contact-info-small {
  margin: 0;
  padding-top: 0;
}

.contact-info p:not(:last-child) {
  margin-bottom: var(--ion-margin);
}
