.login-wrapper {
  margin-bottom: var(--ion-margin-lg);
}

.login-failed-text {
  font-size: 12px;
  margin-top: var(--ion-margin-lg);
  display: block;
}

.login-message {
  display: block;
}
