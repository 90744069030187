.page-header {
  background: var(--ion-color-secondary);
  color: #fff;
}

.page-header-success {
  background: var(--ion-color-success);
}

.page-header.is-success {
  background: var(--ion-color-success);
}

.page-header h1 {
  margin-bottom: 10px;
}

.page-header ion-searchbar {
  --placeholder-color: #fff;
  --placeholder-opacity: 1;
  --placeholder-font-weight: 600;
  padding: 0;
}

.page-header ion-searchbar input {
  border-radius: 6px !important;
}

.page-header .sc-ion-searchbar-md-h {
  height: 60px;
}

.page-header .sc-ion-segment-md-h {
  margin-top: 5px;
}

.page-header ion-segment-button {
  font-weight: 600;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --border-radius: 6px;
  --color: rgba(255, 255, 255, 0.7);
  --color-checked:#fff;
}

.page-wrapper ion-spinner {
  margin: var(--ion-margin-lg) auto;
  display: block;
}
