input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input:not([type]),
textarea {
  width: 100%;
  border: 0;
  height: 30px;
  color: inherit;
  background: var(--ion-color-white);
  outline: none;
  font-weight: 400;
}

textarea {
  height: 160px;
  resize: none;
}

.form-group ion-textarea {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --placeholder-font-weight: 400;
}

:root ion-input {
  --placeholder-font-weight: 400;
  font-size: 16px;
}

ion-radio {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px var(--ion-color-medium);
  transition: box-shadow 0.3s;
  --color-checked: transparent;
  margin: 0 10px 0 0;
}

ion-radio.radio-checked {
  box-shadow: inset 0 0 0 8px var(--ion-color-primary);
}

ion-datetime {
  --placeholder-color: var(--ion-color-dark);
  --padding-start: 0;
  --padding-end: 0;
}

.form-group-datetime ion-icon {
  margin-right: 16px;
}

.datetime-divider {
  margin: 0 var(--ion-margin);
  width: 20px;
  height: 2px;
  background: var(--ion-color-medium);
  border: 0;
}

.form-section-readonly .checkbox-disabled {
  opacity: 1;
}

.form-section-readonly ion-label {
  opacity: 1 !important;
}

.form-section ion-item-divider {
  --padding-top: 5px;
  --padding-bottom: 5px;
  --padding-start: 0;
  margin: 0 0 16px;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--ion-color-primary);
}

.form-group,
.form-group-multiple {
  position: relative;
  background: var(--ion-color-white);
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.05);
  border-radius: 6px;
  margin-bottom: var(--ion-margin);
  padding: 14px 20px;
  --min-height: 0;
}

.form-group.is-readonly,
.form-group-multiple.is-readonly {
  background: transparent;
  box-shadow: none;
  border: 1px solid var(--ion-color-medium);
}

.form-group-simple {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.form-group-item {
  --min-height: 0;
}

.form-group-multiple {
  padding: 14px 0;
}

ion-select {
  font-weight: 400;
  --padding-top: 5px;
  --padding-start: 0;
  --padding-end: 20px;
}

.form-group-multiple ion-item:not(:first-child) {
  border-top: 2px solid var(--ion-background-color);
}

.form-group-multiple.is-readonly ion-item:not(:first-child) {
  border-top: 1px solid var(--ion-color-medium);
}

.form-group-multiple ion-item:not(:first-child) {
  --padding-top: 10px;
}

.form-group-multiple ion-item:not(:last-child) {
  --padding-bottom: 10px;
}

.form-group + [class^='form-group'] {
  margin-top: -15px;
}

.page-header .form-group {
  background: var(--ion-color-primary);
  box-shadow: none;
}

ion-label,
label {
  font-weight: 600;
  font-size: 12px;
}

.form-group ion-label.ios,
.form-group-multiple ion-label.ios {
  font-size: 13px !important;
  margin-top: 5px;
}

.form-group-radio ion-label.ios {
  font-size: 16px !important;
}

.form-group-cb ion-label {
  white-space: pre-wrap !important;
  overflow: visible !important;
  margin-left: 10px;
}

.form-group-images {
  flex-wrap: wrap;
  margin: 0 -5px;
}

.image-col {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 0 5px;
  margin-bottom: 10px;
  position: relative;
}

.form-group-images ion-item {
  background: var(--ion-color-white);
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.05);
  border-radius: 6px;
  width: 100%;
  position: relative;
  --min-height: 100px;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --inner-border-width: 0;
  --inner-padding-top: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --inner-padding-bottom: 0;
}

@media (min-width: 600px) {
  .form-group-images ion-item {
    --min-height: 120px;
  }
}

@media (min-width: 768px) {
  .form-group-images ion-item {
    --min-height: 150px;
  }
}

.form-group-images ion-item img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.form-group-images .icon-add {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-group-images .icon-remove {
  position: absolute;
  display: block;
  top: -12px;
  right: -5px;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background: var(--ion-color-danger);
}

.form-group-images .icon-remove::before,
.form-group-images .icon-remove::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 1px;
  background: #fff;
}

.form-group-images .icon-remove::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.form-group-images .icon-remove::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.form-group span {
  font-size: 12px;
  color: var(--ion-color-primary);
}

.form-group-icon {
  position: absolute;
  top: 50%;
  right: 0;
  left: auto;
  transform: translateY(-50%);
  margin: 0 !important;
}

.form-group-multiple .form-group-icon {
  right: 20px;
}

.page-header .form-group span {
  color: var(--ion-color-white);
}

.native-input.sc-ion-input-md {
  -webkit-padding-start: 0;;
  padding-inline-start: 0;
}

.form-value {
  padding-right: 25px;
}

ion-label.md + .form-value {
  margin-top: 5px;
}

label + ion-toggle.ios {
  margin-left: 16px;
}

.alert-radio-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-md {
  padding-bottom: 8px;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--ion-color-light-tint);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: var(--ion-color-light);
}