.information-title {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 576px) {
  .information-single-image {
    margin-right: -20px;
  }

  .information-title {
    max-width: 350px;
  }
}
