ion-button {
  font-size: 16px !important;
  font-weight: 600;
  height: 60px;
  text-transform: none;
  border: 0;
  --border-radius: 6px;
  --padding-start: 30px;
  --padding-end: 30px;
}

ion-button.ion-color-primary {
  --box-shadow: 0 6px 10px 0 rgba(216,67,20,0.36);
}

ion-button.ion-color-danger {
  --box-shadow: 0 6px 10px 0 rgba(255,81,81,0.36);
}

ion-button.ion-color-success {
  --box-shadow: 0 6px 10px 0 rgba(104,192,135,0.36);
}

ion-button.ion-color-medium,
ion-button.ion-color-light {
  --ion-color-contrast: var(--ion-color-light-white, #fff) !important;
}

ion-button[disabled] {
  --opacity: 1;
  --box-shadow: 0;
}

ion-toolbar ion-button[disabled] {
  --opacity: 0.5;
}
