.zignsec-iframe {
  width: 100%;
  min-height: 290px;
  border: 0;
}

.iframe-container {
  min-height: 297px;
  display: flex;
  align-items: center;
  justify-content: center;
}
