.app-header ion-toolbar {
  --border-color: transparent;
}

.app-header ion-toolbar ion-button {
  font-weight: 600;
}

.app-header ion-title {
  font-size: 16px;
}
