
/* Ionic Variables and Theming. For more information, please see
// https://beta.ionicframework.com/docs/theming/
// The app direction is used to include
// rtl styles in your app. For more information, please see
// https://beta.ionicframework.com/docs/layout/rtl
// $app-direction: ltr;
// Ionic Colors
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic provides eight layered colors
// that can be changed to theme an app. Additional colors can be
// added as well (see below). For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
// To easily create custom color palettes for your app’s UI,
// check out our color generator:
// https://beta.ionicframework.com/docs/theming/color-generator
*/


:root {
  --ion-color-primary: #F05423;
  --ion-color-primary-rgb: 240,84,35;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #d34a1f;
  --ion-color-primary-tint: #f26539;

  --ion-color-secondary: #FF6738;
  --ion-color-secondary-rgb: 255,103,56;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #e05b31;
  --ion-color-secondary-tint: #ff764c;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112,68,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #68C087;
  --ion-color-success-rgb: 104,192,135;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #5ca977;
  --ion-color-success-tint: #77c693;

  --ion-color-success-secondary: #5aaf73;
  --ion-color-success-secondary-rgb: 90,175,115;
  --ion-color-success-secondary-contrast: #000000;
  --ion-color-success-secondary-contrast-rgb: 0,0,0;
  --ion-color-success-secondary-shade: #4f9a65;
  --ion-color-success-secondary-tint: #6bb781;

  --ion-color-warning: #FF9142;
  --ion-color-warning-rgb: 255,145,66;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0803a;
  --ion-color-warning-tint: #ff9c55;

  --ion-color-danger: #FF5151;
  --ion-color-danger-rgb: 255,81,81;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e04747;
  --ion-color-danger-tint: #ff6262;

  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0,0,0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;

  --ion-color-medium: #999999;
  --ion-color-medium-rgb: 153,153,153;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #878787;
  --ion-color-medium-tint: #a3a3a3;

  --ion-color-light: #C6C7CA;
  --ion-color-light-rgb: 198,199,202;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #aeafb2;
  --ion-color-light-tint: #cccdcf;

  --ion-color-white: #fff;
  --ion-color-white-rgb: 244, 244, 244;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;

  --ion-color-success-alt: #53AC73;
  --ion-color-success-rgb-alt: 83,172,115;
  --ion-color-success-contrast-alt: #ffffff;
  --ion-color-success-contrast-rgb-alt: 255,255,255;
  --ion-color-success-shade-alt: #5ca977;
  --ion-color-success-tint-alt: #77c693;
}

.ion-color-white {
  background: #fff;
}

/* Additional Ionic Colors
// --------------------------------------------------
// In order to add colors to be used with Ionic components,
// the color should be added as a class with the convention `.ion-color-{COLOR}`
// where `{COLOR}` is the color to be used on the Ionic component
// and each variant is defined for the color. For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
*/

.ion-color-favorite {
  --ion-color-base: #69bb7b;
  --ion-color-base-rgb: 105, 187, 123;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #5ca56c;
  --ion-color-tint: #78c288;
}

.ion-color-text-color-secondary {
  --ion-color-primary: #FFFFFF;
  --ion-color-primary-rgb: 255,255,255;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #e0e0e0;
  --ion-color-primary-tint: #ffffff;
}

.ion-color-success-secondary {
  background-color: var(--ion-color-success-secondary);
  color: var(--ion-color-success-contrast);
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

:root {
  --ion-font-family: 'BrownForBKR', sans-serif;
  --ion-background-color: #F2F3F6;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;

  --ion-padding: 20px;
  --ion-margin: 20px;
  --ion-grid-padding: 10px;

  --ion-margin-lg: 30px;
}

.ion-margin-top-lg {
  margin-top: var(--ion-margin-lg);
}

.ion-margin-bottom-lg {
  margin-bottom: var(--ion-margin-lg);
}

.md {
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: #fff;
  --ion-toolbar-color-activated: #fff;
}
