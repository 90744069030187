.qty-document-status {
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.05);
  border-radius: 6px;
  background: var(--ion-color-white);
  color: var(--ion-color-dark);
  font-size: 18px;
}

.qty-document-status ion-icon {
  margin-bottom: 10px;
}

.qty-document-footer {
  background: var(--ion-color-white);
  color: var(--ion-color-dark);
}
